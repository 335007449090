<template>
    <div>
        <div :id="getId"
            style="height: 100%; width: 100%; z-index:1;min-height: 250px;">
            <div ref="slot">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import {
    	debounce
    }
    from 'voUtils/tools.js';
    export default {
    	voVueComponent: 'voffice-openstreetmap',
    	props: {
    		items: {
    			type: Array,
    			default: function() {
    				return [];
    			}
    		},
    		markerPos: {
    			type: Array,
    			default: function() {
    				return [];
    			}
    		},
    		mapId: {
    			type: String,
    			default: 'map'
    		},
    		highlight: Number,
    		initialPos: {
    			type: Array,
    			default: function() {
    				return [10, 54];
    			}
    		},
    		initialZoom: {
    			type: Number,
    			default: 14
    		},
    		cluster: undefined,
    		selected: Object,
    		draggable: {
    			type: [String, Boolean],
    			default: true // true, false, center 
    		},
    		scrollWheelZoom: {
    			type: [String, Boolean],
    			default: true // true, false, center       
    		},
    		doubleClickZoom: {
    			type: [String, Boolean],
    			default: true // true, false, center    
    		},
    	},
    	created: function() {
    		var vc = this;
    		this.onMarkerClicked = function(a) {
    			vc.$emit('update:selected', a);
    		};
    	},
    	mounted: function() {
    		this.createMap();
    	},
    	methods: {
    		createMap() {
    			const tiles = window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Points &copy 2012 LINZ'
    			});
    			this.icon = window.L.divIcon({
    				html: `<svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px"  viewBox="0 0 24 24" fill="none"><path fill="currentColor" fill-rule="evenodd" d="M11.291 21.706 12 21l-.709.706zM12 21l.708.706a1 1 0 0 1-1.417 0l-.006-.007-.017-.017-.062-.063a47.708 47.708 0 0 1-1.04-1.106 49.562 49.562 0 0 1-2.456-2.908c-.892-1.15-1.804-2.45-2.497-3.734C4.535 12.612 4 11.248 4 10c0-4.539 3.592-8 8-8 4.408 0 8 3.461 8 8 0 1.248-.535 2.612-1.213 3.87-.693 1.286-1.604 2.585-2.497 3.735a49.583 49.583 0 0 1-3.496 4.014l-.062.063-.017.017-.006.006L12 21zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" clip-rule="evenodd"/></svg>`,
    				className: "openstreet-marker",
    				iconSize: [36, 36],
    				popupAnchor: [0, -15]
    			});
    			const map = window.L.map(this.getId, {
    				center: new window.L.LatLng(this.composeGeolocation(this.initialPos[1]), this.composeGeolocation(this.initialPos[0]), {
    					icon: this.icon
    				}),
    				fullscreenControl: true,
    				zoom: this.initialZoom,
    				dragging: this.draggable,
    				doubleClickZoom: this.doubleClickZoom,
    				scrollWheelZoom: this.scrollWheelZoom,
    				layers: [tiles]
    			});
    			map.on('popupopen', function(e) {
    				var px = map.project(e.target._popup._latlng);
    				px.y -= e.target._popup._container.clientHeight / 2;
    				map.panTo(map.unproject(px), {
    					animate: false
    				});
    			});
    			this.markers = [];
    			this.map = map;
    			if (this.markerPos && this.markerPos.length > 0) {
    				this.updateSingleMarker();
    			}
    		}, updateSingleMarker() {
    			if (this.map) {
    				this.deleteMarkers();
    				const marker = new window.L.marker(new window.L.LatLng(this.composeGeolocation(this.markerPos[1]), this.composeGeolocation(this.markerPos[0])), {
    					icon: this.icon
    				});
    				marker.addTo(this.map);
    				this.map.panTo(new window.L.LatLng(this.composeGeolocation(this.markerPos[1]), this.composeGeolocation(this.markerPos[0])));
    			}
    		}, deleteMarkers() {
    			if (this.markers) {
    				this.map.removeLayer(this.markers);
    			}
    			if (this.clusterObject) {
    				this.map.removeLayer(this.clusterObject);
    			}
    		}, updateMarkers() {
    			if (this.map) {
    				this.deleteMarkers();
    				var clusters = window.L.markerClusterGroup({
    					animate: false
    				});
    				var markers = [];
    				if (this.items.length > 0) {
    					for (var i = 0; i < this.items.length; i++) {
    						var unit = this.items[i];
    						if (!unit || !unit.loc || !unit.loc.coordinates) {
    							continue;
    						}
    						const marker = new window.L.marker(new window.L.LatLng(this.composeGeolocation(unit.loc.coordinates[1]), this.composeGeolocation(unit.loc.coordinates[0])), {
    							icon: this.icon,
    						}).setBouncingOptions({
    							bounceHeight: 30,
    							bounceSpeed: 54,
    							exclusive: true,
    						});
    						if (i === 0) {
    							this.map.flyTo(marker.getLatLng());
    						}
    						this.markers.push(marker);
    						marker.mydata = {
    							data: unit
    						};
    						marker.on('click', () => {
    							this.$emit('update:selected', marker.mydata.data);
    						}).bindPopup(this.$refs.slot, {
    							minWidth: 320,
    							autopan: true
    						});
    						marker.getPopup().on(('remove'), () => {
    							this.$emit('update:selected', {});
    						});
    						if (this.cluster) {
    							clusters.addLayer(marker);
    						} else {
    							marker.addTo(this.map)
    						}
    					}
    				}
    				if (this.cluster) {
    					this.clusterObject = clusters;
    					this.map.addLayer(clusters)
    				}
    			}
    		}, composeGeolocation(input) {
    			return input.toFixed(6) + Math.floor(Math.random() * 4);
    		}, unHighlightMarker(m) {
    			if (this.highlightedMarker) {
    				if (this.highlightedMarker._icon) {
    					this.highlightedMarker._icon.classList.remove("animate-marker");
    				}
    				this.highlightedMarker = undefined;
    			}
    		}, highlightMarker: function(m) {
    			this.highlightedMarker = m;
    			this.map.once('moveend zoomend', () => {
    				const parent = this.clusterObject.getVisibleParent(m);
    				if (parent && parent.spiderfy) {
    					parent.spiderfy();
    				}
    				if ((this.highlightedMarker === m || !this.highlightedMarker) && m._icon) {
    					m._icon.classList.add("animate-marker");
    					m.bounce(2);
    				}
    				if (!this.highlightedMarker === m) {
    					this.unHighlightMarker(this.highlightedMarker);
    				}
    			});
    		}, findMarkerById(id) {
    			if (this.markers) {
    				for (let m of this.markers) {
    					if (m.mydata.data && m.mydata.data._id == id) {
    						return m;
    					}
    				}
    			}
    		}
    	},
    	watch: {
    		items: {
    			deep: true,
    			handler: function(nv) {
    				this.updateMarkers();
    			}
    		},
    		highlight: function(id) {
    			this.unHighlightMarker(this.highlightedMarker);
    			if (id) {
    				let m = this.findMarkerById(id);
    				if (m) {
    					if (!m.getPopup().isOpen()) {
    						this.map.closePopup();
    					}
    					this.map.flyTo(m.getLatLng(), Math.max(this.map.getZoom(), 13), {
    						animate: true,
    						duration: 0.4
    					});
    					this.highlightMarker(m);
    				}
    			}
    		},
    		markerPos: {
    			deep: true,
    			handler: function(val, oldVal) {
    				this.updateSingleMarker();
    			}
    		},
    		selected: function(nv) {
    			this.unHighlightMarker(this.highlightedMarker);
    			var m = this.findMarkerById(nv._id);
    			if (m) {
    				m.openPopup();
    			} else {
    				this.map.closePopup();
    			}
    		}
    	},
    	computed: {
    		getId: function() {
    			return this.mapId;
    		}
    	}
    };
</script>
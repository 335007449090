import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

		/*	new Mmenu(document.querySelector('#mobilemenu'), {
				onClick: {
					close: true
				}
			}); */

	},

	methods: {

		navbarToggle: function () {

			$('.mob-menu').slideToggle();


		},
		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};